export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['event_id'],
    properties: {
      status: {
        title: '狀態',
        type: 'string',
        enum: ['pending', 'success', 'failure'],
        enumNames: ['報名中', '報名成功', '取消報名'],
        default: 'pending',
      },
      serial_number: {
        title: '報名序號',
        type: 'number',
        readOnly: true,
      },
      is_check_in: {
        title: '已報到',
        type: 'boolean',
        default: false,
      },
      owner: {
        title: '用戶',
        type: 'string',
      },
      member_id: {
        title: '會員編號',
        type: 'number',
      },
      name: {
        title: '姓名',
        type: 'string',
      },
      certificate_number: {
        title: '專利師證書字號',
        type: 'string',
      },
      agency_name: {
        title: '執行業務處所',
        type: 'string',
      },
      phone: {
        title: '電話',
        type: 'string',
      },
      email: {
        title: 'EMAIL',
        type: 'string',
      },
      contact_address: {
        type: 'object',
        title: '聯絡地址',
        properties: {
          city: {
            type: 'string',
          },
          district: {
            type: 'string',
          },
          zip: {
            type: 'string',
          },
          detail: {
            type: 'string',
          },
        },
      },
      private_file: {
        title: '附加檔案',
        type: 'string',
      },
      invoice: {
        type: 'object',
        title: '發票/收據開立',
        properties: {
          type: {
            type: 'string',
            title: '類型',
            enum: ['personal', 'company'],
            enumNames: ['個人（二聯式）', '公司報帳（三聯式）'],
            default: 'personal',
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: {
                    enum: ['personal'],
                  },
                },
              },
              {
                properties: {
                  type: {
                    enum: ['company'],
                  },
                  invoice_title: {
                    type: 'string',
                    title: '發票/收據抬頭',
                  },
                  tax_id_number: {
                    type: 'string',
                    title: '統一編號',
                  },
                },
              },
            ],
          },
        },
      },
      meal_info: {
        title: '用餐資訊',
        type: 'string',
        enum: ['葷', '素(全素)', '素(蛋奶素)', '不需用餐'],
      },
      trademark_info: {
        title: '商標代理人字號',
        type: 'string',
      },
      id_card_number: {
        title: '身分證字號',
        type: 'string',
      },
      national_examination_year: {
        title: '通過專利師國考年度(西元)',
        type: 'string',
      },
      birthday: {
        title: '出生年月日',
        type: 'string',
      },
      expertise: {
        title: '專業領域(個人專長，非國考類別)',
        type: 'string',
      },
      bank_account_last_5_digits: {
        title: '匯款帳號後5碼',
        type: 'string',
      },
      note: {
        title: '備註',
        type: 'string',
      },
    },
  },
  uiSchema: {
    serial_number: {
      'ui:help': '當狀態修改為[報名成功]時，系統會自動生成本序號。',
    },
    owner: {
      'ui:widget': 'admin-select-user-widget',
    },
    note: {
      'ui:widget': 'textarea',
    },
    contact_address: {
      'ui:field': 'rev-address-field',
    },
    private_file: {
      'ui:widget': 'private-file-upload-widget',
    },
  },
};
