import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {formSpec} from './form-spec';
import AdminSelectUserWidget from '../../Generators/AdminResource/AdminSelectUserWidget';
import RjsfAddressField from '../../Components/RjsfAddressField';
import RjsfPrivateFileUploadWidget from '../../Components/RjsfPrivateFileUploadWidget';
import {genExcelFile} from '../../Utils/ExportUtil';
import * as AllExports from '../../twpaa-export';

const pageSize = 100;

export default function AdminEventCheckInPage(props) {
  const {id} = qs.parse(props.location.search);
  const [course, setCourse] = React.useState(null);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [modalData, setModalData] = React.useState({
    visible: false,
  });
  const [selectedTableRows, setSelectedTableRows] = React.useState([]);
  const [status, setStatus] = React.useState('');
  const [tmpPhone, setTmpPhone] = React.useState('');
  const [tmpEmail, setTmpEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isCheckIn, setIsCheckIn] = React.useState(null);
  const [downloadLink, setDownloadLink] = React.useState(null);

  const fetchData = React.useCallback(
    async (forDownload = false) => {
      if (id) {
        let err, resp;

        [err, resp] = await AppActions.tryError(
          JStorage.fetchOneDocument('product', {id}),
        );
        if (!err) {
          setCourse(resp);
        }

        const extraQuery = {};
        let sorting = ['-created'];

        if (status) {
          extraQuery.status = status;
          sorting = ['serial_number'];
        }

        if (phone) {
          extraQuery.phone = {$regex: phone};
        }

        if (email) {
          extraQuery.email = {$regex: email};
        }

        if (isCheckIn !== null) {
          extraQuery.is_check_in = isCheckIn
            ? isCheckIn
            : {
                $ne: true,
              };
        }

        if (forDownload) {
          return AppActions.tryError(
            JStorage.fetchAllDocuments(
              'event_registration',
              {
                event_id: id,
                ...extraQuery,
              },
              sorting,
              {product: 0},
            ),
          );
        }

        [err, resp] = await AppActions.tryError(
          JStorage.fetchDocuments(
            'event_registration',
            {
              event_id: id,
              ...extraQuery,
            },
            sorting,
            paging,
            {product: 0},
          ),
        );
        if (!err) {
          setFetchResp(resp);
        }
      }
    },
    [id, status, phone, email, isCheckIn, paging],
  );

  React.useEffect(() => {
    setSelectedTableRows([]);
  }, [status]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onTableRowSelectionChange = React.useCallback((
    selectedRowKeys, //: React.Key[],
    selectedRows, //: DataType[],
  ) => {
    setSelectedTableRows(selectedRows);
  }, []);

  return (
    <Wrapper>
      <h1>
        報名列表 /{' '}
        <a
          href={`/admin/products/?action=detail&id=${id}`}
          onClick={(e) => {
            e.preventDefault();
            window.open(`/admin/products/?action=detail&id=${id}`, '_blank');
          }}>
          {course?.name || id}
        </a>
      </h1>

      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
        <Ant.Button
          size="small"
          onClick={async () => {
            if (!window.confirm('即將發放點數，確定進行嗎?\n此動作無法還原')) {
              return;
            }

            const [err, resp] = await AppActions.tryError(
              AppActions.twpaaAssignStudyHours({
                event_id: id,
              }),
            );
            if (err) {
              Ant.message.warn('API Error: ' + err);
            } else {
              Ant.message.success(
                '發放成功: \n' + JSON.stringify(resp, null, 2),
              );
            }
          }}>
          發放時數
        </Ant.Button>
        <Ant.Button
          size="small"
          onClick={async () => {
            const [err, resp] = await fetchData(true);
            if (err) {
              Ant.message.warn('API Error: ' + err);
            } else {
              if (resp.length > 0) {
                const downloadLink = await genExcelFile(
                  AllExports.EventRegistration.exportSpec,
                  resp,
                  course,
                );
                setDownloadLink(downloadLink);
              }
            }
          }}>
          匯出
        </Ant.Button>
        {downloadLink && (
          <Ant.Button
            type="link"
            size="small"
            download={'報名紀錄.xlsx'}
            href={downloadLink}>
            點此下載
          </Ant.Button>
        )}
      </div>

      <h3 style={{marginTop: 30}}>查詢</h3>
      <div>
        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <label>手機</label>
          <Ant.Input
            style={{width: 120}}
            value={tmpPhone}
            onChange={(e) => setTmpPhone(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 10,
          }}>
          <label>Email</label>
          <Ant.Input
            style={{width: 120}}
            value={tmpEmail}
            onChange={(e) => setTmpEmail(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 8,
          }}>
          <Ant.Button
            size="small"
            type="primary"
            onClick={() => {
              setPhone(tmpPhone);
              setEmail(tmpEmail);
            }}>
            確認
          </Ant.Button>
          <Ant.Button
            size="small"
            type="link"
            onClick={() => {
              setPhone('');
              setTmpPhone('');
              setEmail('');
              setTmpEmail('');
            }}>
            清除
          </Ant.Button>
        </div>
      </div>

      <h3 style={{marginTop: 30}}>過濾</h3>
      <div style={{display: 'flex', gap: 20}}>
        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <label>報名狀態</label>
          <Ant.Select style={{width: 120}} value={status} onChange={setStatus}>
            {[
              {value: '', label: '不指定'},
              {value: 'pending', label: '報名中'},
              {value: 'success', label: '報名成功'},
              {value: 'failure', label: '取消報名'},
            ].map((it) => {
              return (
                <Ant.Select.Option key={it.value} value={it.value}>
                  {it.label}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <label>報到狀態</label>
          <Ant.Select
            style={{width: 120}}
            value={isCheckIn}
            onChange={setIsCheckIn}>
            {[
              {value: null, label: '不指定'},
              {value: false, label: '未報到'},
              {value: true, label: '已報到'},
            ].map((it) => {
              return (
                <Ant.Select.Option key={it.value} value={it.value}>
                  {it.label}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
        </div>
      </div>

      <h3 style={{marginTop: 30}}>選取</h3>
      <div>
        <div style={{marginBottom: 6}}>
          目前已選取 {selectedTableRows.length} 筆紀錄
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <Ant.Button
            disabled={selectedTableRows.length === 0 || status !== 'success'}
            onClick={async () => {
              if (!window.confirm('確定要將所選紀錄設定為 已報到 嗎?')) {
                return;
              }

              let processed = 0;
              let failed = 0;
              AppActions.setLoading(true);
              for (const record of selectedTableRows) {
                if (!record.is_check_in) {
                  try {
                    await JStorage.updateDocument(
                      'event_registration',
                      {
                        id: record.id,
                      },
                      {is_check_in: true},
                    );
                  } catch (ex) {
                    failed++;
                  }
                  await AppActions.delay(100);
                }
                if (processed % 5 === 0) {
                  Ant.message.success(
                    `正在處理 ${processed}/${selectedTableRows.length} ...`,
                  );
                }
                processed++;
              }
              AppActions.setLoading(false);

              Ant.message.success('全部處理完畢');
              if (failed > 0) {
                Ant.message.info(`有 ${failed} 筆未完成, 請查看列表`);
              }

              setSelectedTableRows([]);
              fetchData();
            }}>
            設為已報到
          </Ant.Button>
        </div>
      </div>

      <h3 style={{marginTop: 30}}>報名紀錄</h3>
      <Ant.Table
        columns={[
          {
            title: '報名序號',
            key: 'serial_number',
            render: (_, record) => {
              return !record.serial_number
                ? '---'
                : `${course.registration_number_list || ''}${(
                    '0000' + record.serial_number || ''
                  ).slice(-4)}`;
            },
          },
          {
            title: '報名狀態',
            key: 'status',
            render: (_, record) => {
              const StatusDisplay = {
                success: '報名成功',
                failure: '取消報名',
              };
              return StatusDisplay[record.status] || '報名中';
            },
          },
          {
            title: '報到狀態',
            key: 'is_check_in',
            render: (_, record) => {
              return record.is_check_in ? '是' : '否';
            },
          },
          {
            title: '會員編號',
            key: 'member_id',
            render: (_, record) => {
              if (typeof record.member_id !== 'number') {
                return '非會員';
              }
              return ('0000' + record.member_id).slice(-4);
            },
          },
          {
            title: '姓名',
            key: 'name',
            render: (_, record) => {
              return record.name;
            },
          },
          {
            title: '電話',
            key: 'phone',
            render: (_, record) => {
              return record.phone;
            },
          },
          {
            title: 'EMAIL',
            key: 'email',
            render: (_, record) => {
              return record.email;
            },
          },
          {
            title: '',
            key: 'actions',
            render: (_, record) => {
              return (
                <Ant.Button
                  onClick={() =>
                    setModalData({
                      visible: true,
                      record,
                      fetchData,
                    })
                  }>
                  修改
                </Ant.Button>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedTableRows.map((it) => it.id),
          onChange: onTableRowSelectionChange,
        }}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
      <DetailModal
        close={() => setModalData({visible: false})}
        {...modalData}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  h1 {
    font-size: 28px;
  }
`;

function DetailModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={900}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {record} = props;
  const rjsfProps = {
    widgets: {
      'admin-select-user-widget': AdminSelectUserWidget,
      'private-file-upload-widget': RjsfPrivateFileUploadWidget,
    },
    fields: {
      'rev-address-field': RjsfAddressField,
    },
  };

  return (
    <Wrapper>
      <h2>編輯記錄</h2>
      {formSpec && (
        <GenericForm
          {...formSpec}
          rjsfProps={rjsfProps}
          instance={record}
          renderCustomSubmitButton={({values: formData}) => {
            return (
              <Ant.Button
                type="primary"
                style={{marginTop: 20}}
                onClick={async () => {
                  if (
                    record.status !== 'success' &&
                    formData.status === 'success'
                  ) {
                    if (
                      window.confirm(
                        '確認要將狀態轉為報名成功嗎?\n系統將給予報名編號，此動作無法還原',
                      )
                    ) {
                      const [err] = await AppActions.tryError(
                        AppActions.twpaaApproveEventRegistration({
                          id: record.id,
                          data: formData,
                        }),
                      );
                      if (!err) {
                        Ant.message.success('成功報名');
                        props.close();
                        setTimeout(() => {
                          props.fetchData();
                        }, 100);
                      } else {
                        Ant.message.warn('API Error');
                      }
                    }
                    return; // early return
                  }

                  const [err] = await AppActions.tryError(
                    JStorage.updateDocument(
                      'event_registration',
                      {
                        id: record.id,
                      },
                      formData,
                    ),
                  );
                  if (!err) {
                    Ant.message.success('成功更新');
                    props.close();
                    setTimeout(() => {
                      props.fetchData();
                    }, 100);
                  } else {
                    Ant.message.warn('API Error');
                  }
                }}>
                修改
              </Ant.Button>
            );
          }}
        />
      )}
    </Wrapper>
  );
}
